import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { ClientApplicationService } from '../services/client-application.service';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Tenant } from '../models/tenant';
import { BehaviorSubject, observable, Subject } from 'rxjs';
import { Unit } from '../models/unit';
import { Observable } from 'rxjs/internal/Observable';
import { TenantService } from '../services/tenant.service';
import { map, startWith } from 'rxjs/operators';
import { requiredSelection } from '../shared/validators';

@Component({
  selector: 'app-add-application.dialog',
  templateUrl: './addApplication.dialog.component.html',
  styleUrls: ['./addApplication.dialog.component.css']
})

export class AddClientApplicationDialogComponent {

  selectedTenant: Observable<Tenant>;
  units: BehaviorSubject<Unit[]>;
  selectedTenants: Observable<Tenant[]>;

  tenantSubject: Subject<Tenant> = new Subject<Tenant>();

  constructor(public dialogRef: MatDialogRef<AddClientApplicationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private tenantService: TenantService,
              public appService: ClientApplicationService) {

  }

  formControl = new FormControl('', [
    Validators.required
  ]);
  tenantControl = new FormControl('', [
    requiredSelection
  ]);
  pharmacyControl = new FormControl('', [
    Validators.required
  ]);
  resourceControl = new FormControl('', [
    Validators.required
  ]);

  ngOnInit() {
    this.units = new BehaviorSubject(null);
    this.selectedTenants = this.tenantControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return value && typeof value === 'string' ? this.filterTenants(value) : this.data.tenantList;
      }),
    );
  }

  // event handler for the select element's change event
  onTenantChanged(event: any) {
    let tenantId = event.option.value.id;
    this.data.tenantId = tenantId;
    this.selectedTenant = this.getTenant(tenantId);

    this.selectedTenant.subscribe(value => {

      if (!value.units){
        value.units = new Unit[0];
      }

      this.units.next(value.units);
    });

    this.data.unitId = ''
    this.pharmacyControl.setErrors({'incorrect': true});
  }

  displayTenantName(tenant?: Tenant): string | undefined {
    return tenant ? tenant.name : undefined;
  }

  getTenant(id: string): Observable<Tenant> {
    return this.tenantService.getTenant(id).pipe(map(ts => ts));
  }

  filterTenants(value: string) { 
    let filter = value?.toLowerCase();
    let currValues: Tenant[] = this.data.tenantList;
    return currValues.filter(option => option.name.toLowerCase().includes(filter));
  }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmAdd(): void {
    this.appService.addClientApplication(this.data);
  }
}
