import { UserTenant } from "./userTenant";

export class User {
    id: string;
    userName: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    tenantId: string;
    tenantName: string;
    roles: string[];
    isActive: boolean;
    isTerminated: boolean;
    isADUser: boolean;
    sendRegistrationEmail: boolean;
    userTenants: UserTenant[];

    getStatus() {
        if (this.isTerminated) { return 'Terminated'; }

        return this.isActive ? 'Active' : 'Inactive';
    }
}
