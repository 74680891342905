import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorResponse } from '../models/error';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class ToasterService {

    constructor(private toastr: ToastrService) {    }

    success(message: string) {

        this.toastr.success(message, 'Success', {
            positionClass: 'toast-top-center',
            enableHtml :  true
        });
    }

    error(errName: string, errDescription: string, message: string) {

        const title = message;
        const msg = '<br />' + errName + '<p>' + errDescription + '</p>';

        this.toastr.error(msg, title, {
            positionClass: 'toast-top-center',
            enableHtml :  true,
            timeOut: 5000
        });
    }

    friendlyError(errName: string, errDescription: string, message: string) {

        const title = message;
        const msg = errName + ": " + errDescription;

        this.toastr.error(msg, title, {
            positionClass: 'toast-top-center',
            enableHtml :  true,
            timeOut: 5000
        });
    }

    errorResponse(httpError: HttpErrorResponse, message: string) {
        const error = httpError.error;
        const title = message;
        
        if (error) {
            this.raiseError(error, title);
        } else {
            this.raiseHttpError(httpError, title);
        }
    }

    private raiseError(error: ErrorResponse, title: string) {
        let msg = error.title + ": " + error.detail;
        let errorsMessage = '';

        if (error.errors) {
            const errors = error.errors;
            for (let key in errors) {
                let value = errors[key];
                errorsMessage = '<p>' + errorsMessage + value.join("") + '</p>';
            }
        }

        if (errorsMessage.length > 0) {
            msg = msg + '<p>' + errorsMessage + '</p>'
        }

        this.toastr.error(msg, title, {
            positionClass: 'toast-top-center',
            enableHtml :  true,
            timeOut: 5000
        });
    }

    private raiseHttpError(error: HttpErrorResponse, title: string) {
        let msg = error.statusText + ": " + error.message;

        this.toastr.error(msg, title, {
            positionClass: 'toast-top-center',
            enableHtml :  true,
            timeOut: 5000
        });
    }

    info(message: string, title: string){
        this.toastr.info(message, title, {
            positionClass: 'toast-top-center',
            enableHtml :  true
        });
    }
}
